




import { EnumProp, FunctionProp, OptionalProp } from '@/util/prop-decorators';
import * as echarts from 'echarts';
import { sortBy } from 'lodash';
import { DateTime } from 'luxon';
import { Moment } from 'moment';
import { Component, Mixins, Watch } from 'vue-property-decorator';
import ChartSetsMixin from '../ChartSets.vue';
import { ChartAggregationInterval, ChartSet } from '../model';
import { Chart, ChartOptions, CHART_SERIES_COLOR_PALLETE, MultipleTooltipFormatterParams } from './model';
import { ChartPoint } from 'chart.js';

@Component
export default class EChartsLineChart extends Mixins(ChartSetsMixin) {
  @FunctionProp()
  private formatValue?: (value: string | number | Date | Moment, name: string) => string;

  @EnumProp(...Object.values(ChartAggregationInterval))
  private aggregationInterval!: ChartAggregationInterval;

  @FunctionProp()
  private yAxisLabelFormatter?: (value: string | number, index: number) => string;

  @OptionalProp()
  private readonly chartOptions?: ChartOptions;

  public readonly $refs!: { lineChart: HTMLDivElement };

  private chart!: Chart;

  private zoomIntervalStartValue!: string;

  private zoomIntervalEndValue!: string;

  private get options(): ChartOptions {
    const chartConfig: ChartOptions = {
      xAxis: {
        type: 'time',
        axisLabel: {
          formatter: this.xAxisLabelFormatter,
          hideOverlap: true,
        },
      },
      legend: { show: true, data: this.sets.map(({ label }) => label), top: 0 },
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'line',
          axis: 'x',
        },
        formatter: (params: MultipleTooltipFormatterParams): string => {
          const htmlValues = [];

          for (const { seriesIndex, dataIndex, seriesName } of params) {
            const { currentValue, previousValue } =
              this.getCurrentAndPreviousSetPoint({ seriesIndex: Number(seriesIndex), dataIndex }) ?? {};

            if (seriesName?.startsWith('Temperatúr')) {
              // use point date in tooltip
              let pointDate = '';

              if (seriesIndex != undefined) {
                const point = this.getSetPoint(seriesIndex, dataIndex);
                pointDate = this.formatPointDate(point?.x);
              }

              htmlValues.push(`<strong>Letzte Daten:</strong>${pointDate}`);
            } else {
              // default tooltip formatting
              htmlValues.push(`<strong>${seriesName}:</strong> ${currentValue} (${previousValue})`);
            }
          }

          return htmlValues.join('</br>');
        },
      },
      dataZoom: [
        {
          type: 'inside',
          minValueSpan: 1000 * 60 * 60 * 24 * 1,
        },
        {},
      ],
      ...this.chartOptions,
    };
    chartConfig.yAxis = this.buildYAxis(this.sets);
    // Series
    chartConfig.series = this.sets.map(({ label, name, points, color }, index) => ({
      name: label,
      type: 'line',
      color: color ?? CHART_SERIES_COLOR_PALLETE[index],
      yAxisIndex: index && this.isSetDifferentType(this.sets) ? index : undefined,
      encode: { x: 'date', y: 'value' },
      dimensions: ['label', 'name', 'date', 'value'],
      data: points.map(({ x, y }) => [label, name, x as ISODate, Number(y).toFixed(2)]),
      smooth: false,
      showSymbol: false,
    }));
    return chartConfig;
  }

  // We check if there are different types of data so that we use
  // two different yAxis
  private isSetDifferentType(sets: ChartSet[]): boolean {
    const setTypes: string | string[] = [];
    for (let i = 0; i < sets.length; i++) {
      if (sets[i].name.toLowerCase().includes('temperature')) {
        if (setTypes.indexOf('temperature') === -1) {
          setTypes.push('temperature');
        }
      }
      if (sets[i].name.toLowerCase().includes('volume')) {
        if (setTypes.indexOf('volume') === -1) {
          setTypes.push('volume');
        }
      }
      if (sets[i].name.toLowerCase().includes('energy')) {
        if (setTypes.indexOf('energy') === -1) {
          setTypes.push('energy');
        }
      }
    }

    if (setTypes.length === 0) {
      // default to temperature
      setTypes.push('temperature');
    }

    return setTypes.length > 1;
  }

  private get timeDifferenceOfPointsInDays(): number {
    const setPoints = this.sets.flatMap(({ points }) => points);

    const timeOfSetPoints = setPoints.map(({ x }) => x as ISODate);

    const sortedTimeOfSetPoints = sortBy(timeOfSetPoints);

    if (sortedTimeOfSetPoints.length === 0) {
      return 0;
    }

    const firstPoint = DateTime.fromISO(sortedTimeOfSetPoints[0]);

    const lastPoint = DateTime.fromISO(sortedTimeOfSetPoints[sortedTimeOfSetPoints.length - 1]);

    return Math.abs(firstPoint.diff(lastPoint, ['days']).days);
  }

  private get xAxisTimeFormat(): string {
    switch (true) {
      case this.timeDifferenceOfPointsInDays < 5:
        return 'dd LLL HH:mm';
      case this.timeDifferenceOfPointsInDays < 14:
        return 'dd LLL';
      case this.timeDifferenceOfPointsInDays < 30:
        return "K'W' W";
      case this.timeDifferenceOfPointsInDays < 365:
        return 'dd.LL';
      case this.timeDifferenceOfPointsInDays >= 365:
        return 'yyyy LLL';
      default:
        return 'x';
    }
  }

  // Here we build the Y axis
  private buildYAxis(sets: ChartSet[]): any {
    const yAxis = [];

    for (let i = 0; i < sets.length; i++) {
      if (sets[i].name === 'currentEnergy' || sets[i].name === 'currentEnergy_calc') {
        yAxis.push({
          type: 'value',
          axisLabel: {
            formatter: this.yAxisLabelFormatter,
          },
          boundaryGap: ['15%', '15%'],
          scale: true,
          axisPointer: {
            snap: true,
            type: 'line',
          },
        });
      }
      if (sets[i].name === 'currentVolume') {
        yAxis.push({
          type: 'value',
          axisLabel: {
            formatter: this.yAxisLabelFormatter,
          },
          boundaryGap: ['15%', '15%'],
          scale: true,
          axisPointer: {
            snap: true,
            type: 'line',
          },
        });
      }
      if (sets[i].name.toLowerCase().includes('temperature')) {
        yAxis.push({
          type: 'value',
          position: 'left',
          interval: 5,
          axisLabel: {
            formatter: '{value} °C',
          },
          scale: true,
          boundaryGap: ['15%', '15%'],
          axisPointer: {
            snap: true,
            type: 'line',
          },
        });
      }
    }

    if (yAxis.length === 0) {
      // default to temperature config
      yAxis.push({
        type: 'value',
        position: 'left',
        interval: 5,
        axisLabel: {
          formatter: '{value} °C',
        },
        scale: true,
        boundaryGap: ['15%', '15%'],
        axisPointer: {
          snap: true,
          type: 'line',
        },
      });
    }
    if (yAxis.length > 1) {
      yAxis[1].position = 'right';
    }
    return yAxis;
  }

  private zoomIntervalDifferenceInDays(startValue: string, endValue: string): number {
    const start = DateTime.fromJSDate(new Date(startValue));

    const end = DateTime.fromJSDate(new Date(endValue));

    return Math.abs(start.diff(end, ['days']).days);
  }

  private xAxisLabelFormatter(value: number): string {
    const time = DateTime.fromJSDate(new Date(value));

    if (time.day === 1) {
      return time.toFormat('LLL');
    }

    const zoomIntervalDifference = this.zoomIntervalDifferenceInDays(
      this.zoomIntervalStartValue,
      this.zoomIntervalEndValue,
    );

    switch (true) {
      case zoomIntervalDifference < 5:
        return time.toFormat('dd LLL HH:mm');
      case zoomIntervalDifference < 14:
        return time.toFormat('dd LLL');
      case zoomIntervalDifference < 30:
        return time.toFormat("K'W' W");
      case zoomIntervalDifference < 365:
        return time.toFormat('dd.LL');
      case zoomIntervalDifference >= 365:
        return time.toFormat('yyyy LLL');
      default:
        return time.toFormat(this.xAxisTimeFormat);
    }
  }

  private resizeChart(): void {
    this.chart.resize({
      width: this.$refs.lineChart.parentElement?.clientWidth,
      height: 450,
    });
  }

  private created(): void {
    window.addEventListener('resize', this.resizeChart);
  }

  private mounted(): void {
    this.chart = echarts.init(this.$refs.lineChart, undefined, {
      locale: 'de',
      width: this.$refs.lineChart.parentElement?.clientWidth,
      height: 450,
      renderer: 'svg',
    });
    this.chart.setOption<ChartOptions>(this.options, { notMerge: true, lazyUpdate: true });

    this.chart.on('datazoom', () => {
      const options = this.chart.getOption();
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.zoomIntervalStartValue = options?.dataZoom[0].startValue;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.zoomIntervalEndValue = options?.dataZoom[0].endValue;
    });
  }

  private destroyed(): void {
    window.removeEventListener('resize', this.resizeChart);
  }

  private getCurrentAndPreviousSetPoint({
    seriesIndex,
    dataIndex,
  }: {
    seriesIndex: number;
    dataIndex: number;
  }): { currentValue: string | number; previousValue: string | number } | undefined {
    if (seriesIndex === undefined) {
      return { currentValue: 0, previousValue: 0 };
    }

    const { name, points } = this.sets[seriesIndex];

    const point = points[dataIndex];
    const previousPoint = points[dataIndex + 1];
    if (!previousPoint) {
      return { currentValue: Number(point.y), previousValue: 0 };
    }

    const diff = Number(point.y) - Number(previousPoint.y);
    const value = this.formatValue ? this.formatValue(point.y ?? 0, name) : String(diff);

    if (diff === 0) {
      return { currentValue: value, previousValue: 0 };
    }

    const formattedValue = this.formatValue ? this.formatValue(diff, name) : String(diff);
    const prefix = formattedValue.startsWith('-') ? '' : '+';

    return { currentValue: value, previousValue: prefix + formattedValue };
  }

  private getSetPoint(seriesIndex: number, dataIndex: number): ChartPoint | null {
    const { name, points } = this.sets[seriesIndex];

    const point = points[dataIndex] ?? null;

    return point;
  }

  private formatPointDate(dateInput: any): string {
    if (!dateInput) {
      return '';
    }

    const date = new Date(dateInput);

    const formattedDate = `${date.toLocaleDateString('de-DE')}, ${date.toLocaleTimeString('de-DE')}`;
    return formattedDate;

    return '';
  }

  @Watch('sets')
  private onChartDataChange(): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.options.dataZoom[0].startValue = this.zoomIntervalStartValue;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.options.dataZoom[0].endValue = this.zoomIntervalEndValue;
    this.chart.setOption<ChartOptions>(this.options, { notMerge: true, lazyUpdate: true });
  }
}
